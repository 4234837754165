import axios from "axios";
import AuthApi from "@/api/AuthApi";
import AsyncLocalStorage from "@createnextapp/async-local-storage";
import { JWT_TOKEN, JWT_REFRESH_TOKEN } from "../../const";
import store from "../../store";
import router from "../../router";
import qs from "qs";
const API_URL = process.env.API_URL;

import Vue from "vue";

let isTokenRefreshing = false;
const refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export const httpLoginClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
});

export const httpClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});

httpClient.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
  };
  return config;
});

httpClient.interceptors.response.use(undefined, async function (error) {
  const originalRequest = error.config;
  const res = error.response ? error.response : null;

  if (res.status === 401) {
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      const token = await AsyncLocalStorage.getItem(JWT_TOKEN);
      const reToken = await AsyncLocalStorage.getItem(JWT_REFRESH_TOKEN);
      const { content } = await AuthApi.retoken({
        refreshToken: reToken,
        accessToken: token,
        deviceId: navigator.userAgent,
      }).catch(() => {
        if (window.location.pathname !== "/login") {
          store.state.qbox.isDocLoading = false;
          router.push({
            path: `/login?redirectUrl=${window.location.pathname}`,
          });
        }
      });
      await AsyncLocalStorage.setItem(JWT_TOKEN, content.accessToken);
      await AsyncLocalStorage.setItem(JWT_REFRESH_TOKEN, content.refreshToken);
      isTokenRefreshing = false;

      axios.defaults.headers.common.Authorization = `Bearer ${content.accessToken}`;
      // 새로운 토큰으로 지연되었던 요청 진행
      onTokenRefreshed(content.accessToken);
    }
    const retryOriginalRequest = new Promise((resolve) => {
      addRefreshSubscriber((accessToken) => {
        originalRequest.headers.Authorization = "Bearer " + accessToken;
        resolve(axios(originalRequest));
      });
    });
    return retryOriginalRequest;
  } else {
    isTokenRefreshing = false;
    let msg = "서비스 처리중 오류가 발생했습니다.";
    if (res.data) {
      if (res.data.msg) {
        msg = res.data.msg;
      }
    }
    Vue.$toast.error(msg);
  }

  return Promise.reject(error);
});

export const httpClientOnlyRefresh = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  timeout: 3000,
  headers: {},
});

export const uploadClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

uploadClient.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
  };
  return config;
});

uploadClient.interceptors.response.use(undefined, async function (error) {
  const res = error.response ? error.response : null;
  Vue.$toast.clear();
  if (res.status === 500) {
    Vue.$toast.error(res.data.message);
  } else {
    Vue.$toast.error("서비스 처리중 오류가 발생했습니다.");
  }

  return Promise.reject(error);
});

export const downloadClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  responseType: "blob",
});

export const downloadClient2 = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  responseType: "blob",
});

downloadClient2.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
  };
  return config;
});

downloadClient.interceptors.response.use(undefined, async function (error) {
  Vue.$toast.clear();
  Vue.$toast.error("서비스 처리중 오류가 발생했습니다.");
  return Promise.reject(error);
});

downloadClient2.interceptors.response.use(undefined, async function (error) {
  Vue.$toast.clear();
  Vue.$toast.error("서비스 처리중 오류가 발생했습니다.");
  return Promise.reject(error);
});

export const reportClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  responseType: "blob",
});

reportClient.interceptors.request.use((config) => {
  config.headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
  };
  return config;
});

reportClient.interceptors.response.use(undefined, async function (error) {
  Vue.$toast.clear();
  Vue.$toast.error("서비스 처리중 오류가 발생했습니다.");
  return Promise.reject(error);
});
