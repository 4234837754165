import { httpClient } from "../network/httpclient/HttpClientFactory";

const get = () => httpClient.get(`/site`).then((res) => res.data);
const getById = (id) => httpClient.get(`/site/${id}`).then((res) => res.data);
const changePasswd = (param) =>
  httpClient.put("/site/passwd", param).then((res) => res.data);
const update = (param) =>
  httpClient.put("/siteInfo", param).then((res) => res.data);
const getSiteYearPlan = () =>
  httpClient.get(`/site-year-plan`).then((res) => res.data);
const updateiteYearPlan = (param) =>
  httpClient.put(`/site-year-plan`, param).then((res) => res.data);

const getSiteStorage = () => {
  const siteStorageKey = "siteStorage";
  if (localStorage.getItem(siteStorageKey)) {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem(siteStorageKey)));
    });
  } else {
    return get().then((res) => {
      localStorage.setItem(siteStorageKey, JSON.stringify(res.content));
      return JSON.parse(localStorage.getItem(siteStorageKey));
    });
  }
};

export default {
  get,
  getById,
  changePasswd,
  update,
  getSiteYearPlan,
  updateiteYearPlan,
  getSiteStorage,
};
