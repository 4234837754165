import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Mapbox from "mapbox-gl";
Vue.use(Mapbox);
Vue.config.productionTip = false;

Vue.prototype.isConsoleDefine = false;

import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";

const options = {
  position: "top-right",
  timeout: 2000,
};

Vue.use(Toast, options);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
