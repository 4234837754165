import { JWT_REFRESH_TOKEN, USER_ID } from "../const";
import { httpLoginClient } from "../network/httpclient/HttpClientFactory";
import router from "../router";

const login = (param) =>
  httpLoginClient.post("/auth/login", param).then((res) => res.data);
const findpassword = (param) =>
  httpLoginClient.post("/auth/findpassword", param).then((res) => res.data);
const checkSite = (invCode) =>
  httpLoginClient.get(`/auth/checkSite/${invCode}`).then((res) => res.data);
const signup = (param) =>
  httpLoginClient.post("/auth/signup", param).then((res) => res.data);
const retoken = (param) =>
  httpLoginClient.post("/auth/retoken", param).then((res) => res.data);
const checkemail = (param) =>
  httpLoginClient.post("/auth/checkemail", param).then((res) => res.data);
const removeRetoken = () =>
  httpLoginClient
    .delete("/auth/retoken", {
      data: {
        id: localStorage.getItem(USER_ID),
        refreshToken: localStorage.getItem(JWT_REFRESH_TOKEN),
      },
    })
    .then((res) => res.data);

const logout = () => {
  removeRetoken();

  router.push({ name: "Login" });
  localStorage.clear();
};

export default {
  login,
  findpassword,
  checkSite,
  signup,
  retoken,
  logout,
  checkemail,
  removeRetoken,
};
