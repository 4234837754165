<template>
  <!-- container -->
  <div class="container">
    <!-- shadow-box -->
    <div class="shadow-box login-box">
      <!-- login-wrap -->
      <div class="login-wrap">
        <!-- logo -->
        <div class="logo">
          <img src="/img/common/logo.svg" alt="" />
          <span class="logo-text">LOGIN</span>
          <span class="logo-desc">건설현장 품질관리 Q박스 - Cabinet</span>
        </div>
        <!-- // logo -->

        <!-- field-wrapper -->
        <div class="field-wrapper">
          <div class="field-box">
            <div class="label">이메일 ID</div>
            <div class="field">
              <input
                v-model="login.email"
                type="text"
                class="txt"
                placeholder="@ 포함 입력"
              />
              <span class="error">{{ emailError }}</span>
            </div>
          </div>
          <div class="field-box">
            <div class="label">비밀번호</div>
            <div class="field">
              <input
                v-model="login.passwd"
                type="password"
                class="txt"
                @keyup.enter="processLogin"
              />
              <span class="error">{{ passwdError }}</span>
            </div>
          </div>
          <div class="btn-box">
            <button
              type="button"
              class="btn"
              style="margin-bottom: 20px"
              :disabled="btnDisabled"
              @click="processLogin"
            >
              <span>로그인</span>
            </button>
          </div>
        </div>
        <!-- // field-wrapper -->
      </div>
      <!-- // login-wrap -->
    </div>
    <!-- // shadow-box -->
  </div>
  <!-- // container -->
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  USER_ID,
  USER_EMAIL,
  PHOTO_ID,
  AUTO_YN,
  JWT_TOKEN,
  JWT_REFRESH_TOKEN,
  NAME,
  POSITION,
  USER_AUTH,
  SITE_NAME,
  SITE_TYPE,
  SITE_USE_REQUEST,
  SITE_USE_TESTTYPE,
  SITE_USE_MIXTURE_DESIGN,
  SITE_USE_SHOTCRETE,
  SITE_USE_REMICON_BOOK,
  SITE_USE_28SIGN,
  SITE_USE_BOOK_TEMPLATE_EXCEL,
  SITE_USE_DOC_NO,
  SITE_ID,
  SITE_USE_COL_UNIT_SPEC,
  ALLOWED_SYSTEM,
  SYSTEM_TYPE,
  SITE_USE_REQ_CONCAT_TEST_NO,
  SITE_USE_MULTI_REMICON_TEST_NO,
  SITE_USE_DISPLAY_WRITER,
} from "@/const";
import AuthApi from "@/api/AuthApi";
import SystemApi from "@/api/SystemApi";
import SiteApi from "@/api/SiteApi";
export default {
  name: "Login",
  components: {},
  data: () => {
    return {
      login: {
        email: "",
        passwd: "",
        deviceId: navigator.userAgent,
        isLongToken: false,
      },
      emailError: "",
      passwdError: "",
    };
  },
  computed: {
    btnDisabled() {
      return !(this.login.email.length > 2 && this.login.passwd.length > 2);
    },
    ...mapState({
      member: (state) => state.qbox.member,
    }),
  },
  created() {},
  mounted() {
    this.hideLoading();
    this.hideDocLoading();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.redirectUrl = urlParams.get("redirectUrl");
    this.invitationCode = urlParams.get("code");

    const email = localStorage.getItem(USER_EMAIL);
    const isLongToken = localStorage.getItem(AUTO_YN);

    this.login.email = email ? email : "";
    this.login.isLongToken = isLongToken ? isLongToken : true;
  },
  beforeDestroy() {},
  methods: {
    async processLogin() {
      if (!this.login.email) {
        this.emailError = "이메일 ID를 입력하세요.";
        this.login.email.focus();
        return;
      }

      if (!this.login.passwd) {
        this.passwdError = "비밀번호를 입력하세요.";
        this.login.passwd.focus();
        return;
      }
      localStorage.clear();
      AuthApi.login(this.login).then(
        async (value) => {
          if (value) {
            SystemApi.getenums();

            const allowedSystem = value.content.allowedSystem
              ? parseInt(value.content.allowedSystem)
              : 3;

            localStorage.setItem(JWT_TOKEN, value.content.accessToken);
            localStorage.setItem(NAME, value.content[NAME]);
            localStorage.setItem(USER_ID, value.content.id);
            localStorage.setItem(USER_EMAIL, this.login.email);
            localStorage.setItem(POSITION, value.content[POSITION]);
            localStorage.setItem(USER_AUTH, value.content.grantType);
            localStorage.setItem(NAME, value.content.name);
            localStorage.setItem(PHOTO_ID, value.content.photoId);
            localStorage.setItem(ALLOWED_SYSTEM, value.content.allowedSystem);

            const site = await SiteApi.get();

            localStorage.setItem(SITE_ID, site.content.id);
            localStorage.setItem(SITE_NAME, site.content.name);
            localStorage.setItem(SITE_TYPE, site.content.type);
            localStorage.setItem(SITE_USE_REQUEST, site.content.isUseRequest);
            localStorage.setItem(
              SITE_USE_REMICON_BOOK,
              site.content.isUseRemiconBook
            );
            localStorage.setItem(SITE_USE_TESTTYPE, site.content.isUseTestType);
            localStorage.setItem(
              SITE_USE_MIXTURE_DESIGN,
              site.content.isUseMixtureDesign
            );
            localStorage.setItem(SITE_USE_DOC_NO, site.content.isUseDocNo);
            localStorage.setItem(
              SITE_USE_SHOTCRETE,
              site.content.isUseShotcrete
            );
            localStorage.setItem(SITE_USE_28SIGN, site.content.isUse28sign);
            localStorage.setItem(
              SITE_USE_BOOK_TEMPLATE_EXCEL,
              site.content.isUseBookTemplateExcel
            );
            localStorage.setItem(
              SITE_USE_COL_UNIT_SPEC,
              site.content.isUseUnitSpec
            );
            localStorage.setItem(
              SITE_USE_REQ_CONCAT_TEST_NO,
              site.content.isUseRequestConcatTestNo
            );
            localStorage.setItem(
              SITE_USE_MULTI_REMICON_TEST_NO,
              site.content.isUseMultiRemiconTestNo
            );
            localStorage.setItem(
              SITE_USE_DISPLAY_WRITER,
              site.content.isUseDisplayWriter
            );

            this.$router.push({ path: "/main" });
          }
        },
        () => {
          this.passwdError = "가입하지 않았거나 잘못된 비밀번호입니다.";
        }
      );
    },
    movePage(target) {
      if (this.$route.name !== target) {
        this.$router.push({
          name: target,
        });
      }
    },
    moveSignup() {
      if (this.invitationCode) {
        this.$router.push({ path: `/signup?code=${this.invitationCode}` });
      } else {
        this.$router.push({ path: `/signup` });
      }
    },
    onNotification() {},
    ...mapActions([
      "hideLoading",
      "hideDocLoading",
      "setMember",
      "profFileUrl",
    ]),
  },
};
</script>
