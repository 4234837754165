import {
  httpClient,
  reportClient,
} from "../network/httpclient/HttpClientFactory";

import Vue from "vue";

const cabinetInit = () =>
  httpClient.get(`v2/cabinet/init`).then((res) => res.data);

const cabinetCategory = (code) =>
  httpClient.get(`v2/cabinet/categoryDate/${code}`).then((res) => res.data);

const monthCategory = (monthDate) =>
  httpClient
    .get(`v2/cabinet/monthCategory/${monthDate}`)
    .then((res) => res.data);

const testbookList = (monthDate, code) =>
  httpClient
    .get(`v2/cabinet/testbook-list/${monthDate}/${code}`)
    .then((res) => res.data);

const zip = (monthDate, code) =>
  reportClient
    .post(`v2/cabinet/pdf-zip/${monthDate}/${code}`, {})
    .then((res) => {
      console.log(res);
      if (res.data.size === 0) {
        Vue.$toast.error("생성된 PDF 파일이 없습니다");
        return;
      }
      const fileName = `pdfZip.zip`;
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res.data, "pdf-zip");
      } else {
        var a = document.createElement("a");
        var url = (window.URL || window.webkitURL).createObjectURL(res.data);
        a.href = url;
        try {
          a.download = fileName;
        } catch (e) {
          /* ignore for IE */
        }
        a.click();

        (window.URL || window.webkitURL).revokeObjectURL(url);
      }
    });

export default {
  cabinetInit,
  cabinetCategory,
  monthCategory,
  testbookList,
  zip,
};
