import Vue from "vue";
import Router from "vue-router";
import Cabinet from "../components/Cabinet";
import Login from "../components/Login.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Main",
      alias: "/main",
      component: Cabinet,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        bodyClass: "login",
      },
    },
  ],
});

export default router;
