import MutationTypes from '../../MutationTypes';

export default {
    [MutationTypes.WORKER_LIST]: (state, payload) => {
        state.workerList = payload;
    },
    [MutationTypes.CAR_LIST]: (state, payload) => {
        state.carList = payload;
    },
    [MutationTypes.MAP_BOUNDS]: (state, payload) => {
        state.mapBounds = payload;
    },
    [MutationTypes.WORK_LIST]: (state, payload) => {
        state.workList = payload;
    }
};
