<template>
  <div class="content">
    <div class="unity-box">
      <div v-show="isLoading" class="loading-bar">
        <p>Loading... {{ loadingProgress }}%</p>
      </div>

      <VueUnity
        :unity="unityContext"
        width="100%"
        height="100%"
        ref="unityContainer"
      />

      <!-- 레이어 팝업 -->
      <div class="layer-popup" v-if="isPopupVisible" @click.self="closePopup">
        <div class="popup-content">
          <!-- 닫기 버튼 -->
          <button @click="closePopup" class="close-button">✖</button>
          <!-- 팝업 콘텐츠를 여기에 추가하세요 -->
          <h3>{{ popupTitle }}</h3>
          <div>
            <PDFViewer
              :source="pdfSource"
              :controls="pdfControls"
              @download="handleDownload"
              style="
                margin-top: 0px;
                margin-left: 0vh;
                height: 80vh;
                width: 63vw;
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="web-box">
      <div class="header">
        {{ site.name }}
        <button class="download-button" @click="goBack">Back</button>
      </div>
      <div class="content-area">
        <div class="content-header">
          <div class="title">{{ roomName }}</div>
          <div>
            <button
              v-show="activeTab === 'tab3'"
              class="action-button"
              @click="pdfZip"
            >
              다운로드
            </button>
          </div>
        </div>
        <div v-if="activeTab === 'tab0'" class="inner-content tab0">
          <div class="box">
            <span class="link-like" @click="sceneMove(1)">월별성적서방</span>
          </div>
          <div class="box">
            <span class="link-like" @click="sceneMove(2)">공종별검사방</span>
          </div>
        </div>

        <div v-if="activeTab === 'tab1'" class="inner-content tab1">
          <div
            v-for="cabinet in cabinetList"
            :key="cabinet.id"
            class="box"
            @click="onClickCabinet(cabinet)"
          >
            <div class="cabinet-img" />
            <div>{{ cabinet.name }}</div>
          </div>
        </div>

        <div v-if="activeTab === 'tab2'" class="inner-content tab2">
          <!-- tab2의 콘텐츠를 여기에 추가 -->
          <div
            v-for="(category, index) in categoryList"
            :key="index"
            class="box"
            @click="onClickCategory(category)"
          >
            <div class="category-img" />
            <div>{{ category.name }}</div>
          </div>
        </div>

        <div v-if="activeTab === 'tab3'" class="inner-content tab3">
          <div
            v-for="(testbook, index) in testbookList"
            :key="index"
            class="box"
          >
            <div class="icon" />
            <span class="ellipsis-text link-like" @click="detailPdf(testbook)">
              {{ testbook.testNo }} {{ testbook.material
              }}{{ testbook.testItemName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JWT_TOKEN } from "@/const";
import UnityWebgl from "unity-webgl";
import VueUnity from "unity-webgl/vue";
import CabinetApi from "@/api/CabinetApi";
import SiteApi from "@/api/SiteApi";
import { mapActions } from "vuex";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

const server = "";
const Unity = new UnityWebgl({
  loaderUrl: server + "/unity/metaCabinet.loader.js",
  dataUrl: server + "/unity/metaCabinet.data",
  frameworkUrl: server + "/unity/metaCabinet.framework.js",
  codeUrl: server + "/unity/metaCabinet.wasm",
});

window.ReceiveObjectName = function ReceiveObjectName(data) {
  window.vueInstance.ReceiveObjectName(data);
};

export default {
  components: {
    VueUnity,
    PDFViewer,
  },
  data() {
    return {
      unityContext: Unity,
      cabinet: null,
      monthCabinet: null,
      categoryCabinet: null,
      cabinetList: null,
      site: { name: "" },
      roomName: "월별성적서방",
      categoryList: null,
      activeTab: "tab1", // 기본적으로 탭1을 활성화
      activeDate: null,
      activeCategory: null,
      activeScene: 1,
      testbookList: null,
      isPopupVisible: false,
      pdfSource: "",
      pdfControls: [
        "rotate",
        "zoom",
        "catalog",
        "switchPage",
        "download",
        "print",
      ],
      popupTitle: "",
      isLoading: true, // 로딩 상태를 나타내는 변수
      loadingProgress: 0, // 로딩 진행 상황
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  mounted() {
    let token = localStorage.getItem(JWT_TOKEN);

    console.log(token);
    if (!token) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      token = urlParams.get("token");
      if (token) localStorage.setItem(JWT_TOKEN, token);

      if (!token) this.$router.push({ name: "Login" });
    }

    SiteApi.get().then((res) => {
      this.site = res.content;
    });
    this.cabinetInit();
    window.vueInstance = this;

    Unity.on("device", () => alert("click device ..."));

    // 로딩 진행 상황 업데이트
    Unity.on("progress", (percent) => {
      console.log("Unity progress: ", percent);
      this.loadingProgress = Math.round(percent * 100); // 퍼센트 업데이트
      if (percent === 1) {
        this.isLoading = false; // 로딩 완료되면 로딩바 숨기기
      }
    })
      .on("loaded", () => console.log("Unity loaded: success"))
      .on("created", () => {
        console.log("Unity created: success");
        this.unitySuccess();
      });
  },
  beforeDestroy() {},
  destroy() {},
  methods: {
    ...mapActions([]),
    async unitySuccess() {
      if (!this.cabinet) await this.cabinetInit();
      this.unityContext.send(
        "makeCabinetSet",
        "sendAllData",
        JSON.stringify(this.cabinet)
      );
    },
    async cabinetInit() {
      const result = await CabinetApi.cabinetInit();
      this.monthCabinet = result[0].cabinet;
      this.cabinetList = this.monthCabinet;
      this.categoryList = result[1].cabinet;
      this.cabinet = { room: result };
    },
    ReceiveObjectName(data) {
      const cabinetId = data.split("@")[1];

      const findDate = this.monthCabinet.find(
        (data) => data.id === Number(cabinetId)
      );
      this.activeDate = findDate.name;
      this.onClickCabinet(findDate);
    },
    async onClickCabinet(cabinet) {
      this.activeDate = cabinet.name;
      if (this.activeScene === 1) {
        this.unityContext.send(
          "clickEvent",
          "SendSearchData",
          String(cabinet.id)
        );

        const result = await CabinetApi.monthCategory(cabinet.name);
        this.categoryList = result;
        this.activeTab = "tab2";
      } else {
        const result = await CabinetApi.testbookList(
          this.activeDate,
          this.activeCategory
        );
        this.testbookList = result;
        this.activeTab = "tab3";
      }
    },
    async onClickCategory(category) {
      if (category && category.code) this.activeCategory = category.code;
      if (this.activeScene === 1) {
        const result = await CabinetApi.testbookList(
          this.activeDate,
          this.activeCategory
        );
        this.testbookList = result;
        this.activeTab = "tab3";
      } else {
        const result = await CabinetApi.cabinetCategory(this.activeCategory);
        this.categoryCabinet = result;
        this.activeTab = "tab1";
        this.cabinetList = this.categoryCabinet;
      }
    },
    sceneMove(scene) {
      this.activeScene = scene;
      this.unityContext.send("makeCabinetSet", "sendSceneMove", String(scene));

      if (this.activeScene === 1) {
        this.cabinetList = this.monthCabinet;
        this.activeTab = "tab1";
        this.roomName = "월별성적서방";
      }
      if (this.activeScene === 2) {
        this.activeTab = "tab2";
        this.roomName = "공종별성적서방";
      }
    },
    goBack() {
      this.unityContext.send("clickEvent", "sendColorReset");
      if (this.activeScene === 1 && this.activeTab === "tab1") {
        this.activeTab = "tab0";
        return;
      }
      if (this.activeScene === 1 && this.activeTab === "tab2") {
        this.activeTab = "tab1";
        return;
      }
      if (this.activeScene === 1 && this.activeTab === "tab3") {
        this.activeTab = "tab2";
        return;
      }

      if (this.activeScene === 2 && this.activeTab === "tab1") {
        this.activeTab = "tab2";
        return;
      }
      if (this.activeScene === 2 && this.activeTab === "tab2") {
        this.activeTab = "tab0";
        return;
      }
      if (this.activeScene === 2 && this.activeTab === "tab3") {
        this.activeTab = "tab1";
        return;
      }
    },
    openPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    detailPdf(testbook) {
      if (!testbook.pdfFileId) {
        this.$toast.info("생성된 PDF파일이 없습니다.");
        return;
      }
      this.popupTitle = testbook.testNo;
      this.isPopupVisible = true;
      this.pdfSource = this.makeFileUrl(testbook.pdfFileId, 1);
    },
    makeFileUrl(fileId, fileNo) {
      return (
        "/api/file/download/file?" +
        "file_id=" +
        fileId +
        "&file_no=" +
        fileNo +
        "&v=" +
        Date.now()
      );
    },
    handleDownload(data) {
      const link = document.createElement("a");
      link.href = data.src;
      link.download = "downloaded-file.pdf"; // 다운로드 파일 이름 설정
      link.click();
    },
    pdfZip() {
      CabinetApi.zip(this.activeDate, this.activeCategory);
    },
  },
};
</script>

<style scoped>
.loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 페이지의 전체 높이를 사용하여 화면 중앙에 표시 */
  background-color: rgba(
    240,
    248,
    255,
    0.9
  ); /* 밝은 푸른색 계통의 배경으로 변경 */
  color: #2e4a62; /* 어두운 파란색 계통으로 텍스트 색상 변경 */
}

.loading-bar p {
  font-size: 20px;
  color: #333;
}
</style>
