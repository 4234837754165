export const APPLICATION_VERSION = process.env.VERSION;
export const APPLICATION_PHASE = process.env.APPLICATION_PHASE || "REAL";

//LocalStorage
export const NAME = "name";

export const USER_ID = "userId";
export const USER_EMAIL = "userEmail";
export const POSITION = "positionName";
export const LOGINTIME = "logintime";

export const SITE_USE_DISPLAY_WRITER = "siteUseDisplayWriter";
export const SITE_USE_COL_UNIT_SPEC = "siteUseUnitSpec";
export const SITE_USE_BOOK_TEMPLATE_EXCEL = "siteUseBookTemplateExcel";
export const SITE_USE_28SIGN = "siteUse28Sign";
export const SITE_USE_REMICON_BOOK = "siteUseRemiconBook";
export const SITE_USE_REQUEST = "siteUseRequest";
export const SITE_USE_TESTTYPE = "siteUseTestType";
export const SITE_USE_MIXTURE_DESIGN = "siteUseMixtureDesign";
export const SITE_USE_DOC_NO = "siteUseDocNo";
export const SITE_USE_SHOTCRETE = "siteUseShotcrete";
export const SITE_USE_REQ_CONCAT_TEST_NO = "siteUseRequestConcatTestNo";
export const SITE_USE_MULTI_REMICON_TEST_NO = "siteUseMultiRemiconTestNo";
export const SITE_NAME = "siteName";
export const SITE_TYPE = "siteType";
export const USER_AUTH = "userAuthType";
export const AUTO_YN = "autoYn";
export const SIGN_FILE_ID = "signFileId";
export const PROF_FILE_ID = "profFileId";
export const PHOTO_ID = "photoId";
export const JWT_TOKEN = "token";
export const JWT_REFRESH_TOKEN = "refreshtoken";
export const PREFIX_TOKEN = "Bearer ";
export const CLINET_ID = "vgen";
export const CLINET_SECRET = "pass";
export const GRANT_TYPE_LOGIN = "password";
export const GRANT_TYPE_REFRESH = "refresh_token";
export const REQUEST_TYPE = "request_type";
export const ALARM_TYPE = "AlarmType";
export const SITE_ID = "siteId";

export const ALLOWED_SYSTEM = "allowed_system";
export const FILE_URL = process.env.FILE_URL;
export const SYSTEM_TYPE = {
  QTEST: 1,
  QMATERIAL: 2,
};

export const IS_ENUMS = "isEnums";
