import { httpClient } from "../network/httpclient/HttpClientFactory";
import { IS_ENUMS } from "@/const";
const getenums = () => {
  return httpClient.get(`/system/enums`).then((res) => {
    localStorage.setItem(IS_ENUMS, 1);
    for (var [key, value] of Object.entries(res.data.content)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    return res.data.content;
  });
};

const getenum = (key) => {
  if (localStorage.getItem(key)) {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem(key)));
    });
  } else {
    return getenums().then(() => JSON.parse(localStorage.getItem(key)));
  }
};

export default {
  getenums,
  getenum,
};
